import { FaqQuestionFragment } from 'api/contentful/generated/contentful'
import { getUserAuthStatus } from 'driverama-core/analytics/events'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextBody, TextHeader } from 'driverama-core/components/text/Text'
import { useSession } from 'driverama-core/utils/auth'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactNode } from 'react-markdown'
import { logGAEvent } from 'utils/analytics/events'
import { FaqSourcePage } from 'utils/analytics/useLogEvents'
import { SAllLinkButton, SContainer, SContent, SHeading } from './Faq.styled'
import { FaqItem } from './item/FaqItem'

interface Props {
  title: string
  subtitle: string
  questions: FaqQuestionFragment[]
  headingSpacer?: ReactNode
  actionsSpacer?: ReactNode
  className?: string
  allFaqLink?: string
  sourcePage: FaqSourcePage
}

export function Faq({
  title,
  subtitle,
  questions,
  headingSpacer,
  actionsSpacer,
  className,
  allFaqLink,
  sourcePage
}: Props) {
  const { t } = useTranslation(['core'])

  const { session } = useSession()
  const [eventLogged, setEventLogged] = useState<boolean>(false)

  const onOpenFaq = () => {
    if (!eventLogged) {
      logGAEvent('faq_open', {
        ...getUserAuthStatus(session),
        faq_name: title,
        faq_type: sourcePage
      })
      setEventLogged(true)
    }
  }

  if (questions.length === 0) return null

  return (
    <SContainer className={className}>
      <SContent>
        <SHeading variant="column" align="center">
          <TextHeader as="h2" variant={['h5', 'h4', 'h2']}>
            {title}
          </TextHeader>

          <Spacer size={4} />

          <TextBody>{subtitle}</TextBody>
        </SHeading>

        {headingSpacer}

        <div>
          {questions &&
            questions.map(question => (
              <FaqItem
                key={question.sys.id}
                question={question}
                onOpenFaq={onOpenFaq}
              />
            ))}

          {actionsSpacer}

          {allFaqLink && (
            <SAllLinkButton text={t('core:faq_all')} href={allFaqLink} />
          )}
        </div>
      </SContent>
    </SContainer>
  )
}
