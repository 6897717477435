import { TextBody } from 'driverama-core/components/text/Text'
import styled from '@emotion/styled'
import { size, spaceY } from 'driverama-core/styles/spacing'
import { color, time } from 'driverama-core/styles/variables'
import IconPlus from 'images/icons/IconPlus.svg'

export const SContainer = styled.div`
  display: flex;
  flex-direction: column;

  border-top: 2px solid ${color('night-l-800')};
  padding: ${size(5)} ${size(4)};

  &:last-of-type {
    border-bottom: 2px solid ${color('night-l-800')};
  }

  ${spaceY(2)}
`

export const SHeading = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`
export const STitle = styled(TextBody)<{ isOpened: boolean }>`
  text-align: left;
  transition: ${time('control')} color;
  color: ${({ isOpened }) =>
    isOpened ? color('night-d-100') : color('night-text')};
`

export const SContent = styled.div`
  p:last-of-type {
    margin-bottom: 0;
  }
`

export const SToggleButtonIcon = styled(IconPlus)`
  min-width: ${size(5)};
  min-height: ${size(5)};
  transition: ${time('control')} all;
`
