import { layoutGrid } from 'driverama-core/styles/common'
import styled from '@emotion/styled'
import { size } from 'driverama-core/styles/spacing'
import { media } from 'driverama-core/styles/media'
import { Flex } from 'driverama-core/components/Flex'
import { ButtonArrow } from 'driverama-core/components/buttonArrow/ButtonArrow'

export const SContainer = styled.div`
  ${layoutGrid}
`
export const SHeading = styled(Flex)`
  text-align: center;
`

export const SContent = styled.div`
  grid-column-start: col-4;
  grid-column-end: col-9;

  @media ${media.lte('tablet')} {
    grid-column-start: col-1;
    grid-column-end: col-4;
  }
`

export const SAllLinkButton = styled(ButtonArrow)`
  float: right;
  margin-right: ${size(4)};

  // clearfix
  &::after: {
    content: '';
    clear: both;
    display: table;
  }
`
