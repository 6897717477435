import { useState } from 'react'
import { FaqQuestionFragment } from 'api/contentful/generated/contentful'
import {
  SContainer,
  SContent,
  SHeading,
  STitle,
  SToggleButtonIcon
} from './FaqItem.styled'
import { color } from 'driverama-core/styles/variables'
import { ContentfulContent } from 'sections/blog/detail/contentfulContent/ContentfulContent'

interface Props {
  question: FaqQuestionFragment
  onOpenFaq?: () => void
}

export function FaqItem({ question, onOpenFaq }: Props) {
  const [isOpened, setOpened] = useState(false)

  return (
    <SContainer>
      <SHeading
        onClick={() => {
          onOpenFaq?.()
          setOpened(!isOpened)
        }}
        aria-expanded={isOpened}
      >
        <STitle size="large" variant="setup" isOpened={isOpened}>
          {question.title}
        </STitle>

        <SToggleButtonIcon
          css={{
            color: isOpened ? color('night-d-100') : color('night-l-100'),
            transform: isOpened ? 'rotate(45deg)' : 'rotate(0deg)'
          }}
        />
      </SHeading>

      <SContent>{isOpened && <ContentfulContent data={question} />}</SContent>
    </SContainer>
  )
}
