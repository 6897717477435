import { SearchClient } from 'algoliasearch'
import {
  HydrateProps,
  InstantSearchHydrate
} from 'components/algolia/AlgoliaHydrate'
import { ComponentType } from 'react'
import { findResultsState } from 'react-instantsearch-dom/server'
import { z } from 'zod'
import { AlgoliaStockCarDto, SerpState } from '../Serp.types'
import { SerpAlgoliaAtom, SerpStateAtom } from '../SerpAtom'
import { useNaiveHydrateAtoms } from '../SerpAtom.utils'
import { SerpLovs, SerpLovsAtom } from './SerpHydrateAtom'

const ResultsStateDto = z.array(
  z.object({
    queryID: z.string(),
    hits: z.array(AlgoliaStockCarDto)
  })
)

export function useHydrateSerpState(searchState?: SerpState, lovs?: SerpLovs) {
  return useNaiveHydrateAtoms([
    [SerpAlgoliaAtom, searchState?.search],
    [SerpStateAtom, searchState],
    [SerpLovsAtom, lovs]
  ])
}

export async function dehydrateAlgoliaState(
  // eslint-disable-next-line
  Component: ComponentType<HydrateProps & any>, // TODO: find correct type
  options: {
    searchClient: SearchClient
    indexName: string
  },
  serpAtomState?: SerpState,
  serpLovs?: SerpLovs
) {
  function SerpHydrate(
    props: HydrateProps & {
      serpAtomState?: SerpState
      serpLovs?: SerpLovs
    }
  ) {
    useHydrateSerpState(props.serpAtomState, props.serpLovs)

    return InstantSearchHydrate(Component, props)
  }

  const resultsState = await findResultsState(SerpHydrate, {
    ...options,
    serpAtomState,
    serpLovs
  })

  return {
    resultsState,
    queries:
      resultsState.results?.map(
        (res: { rawResults: Array<Record<string, unknown>> }) =>
          ResultsStateDto.parse(
            res.rawResults.filter(
              (i: Record<string, unknown>) => 'queryID' in i
            )
          )
      ) ??
      ResultsStateDto.parse(
        resultsState.rawResults.filter(
          (i: Record<string, unknown>) => 'queryID' in i
        )
      )
  }
}
