import { HydrateProps } from 'components/algolia/AlgoliaHydrate'
import { CarHighlightCarousel } from 'components/algolia/carHighlightCarousel/CarHighlightCarousel'
import { useTranslation } from 'react-i18next'
import { dehydrateAlgoliaState } from 'sections/serp/hydrate/SerpHydrate'
import { searchClient } from 'sections/serp/Serp.utils'
import { CarDetailSourcePage } from 'utils/analytics/useLogEvents'

interface Props {
  page: CarDetailSourcePage
  headless?: boolean
  showTrack?: boolean
  hideBrowseAll?: boolean
  multiIndex?: boolean
}

export function NewArrivals({
  resultsState,
  widgetsCollector,
  page,
  headless,
  showTrack,
  hideBrowseAll,
  multiIndex
}: HydrateProps & Props) {
  const { t } = useTranslation(['core'])
  return (
    <CarHighlightCarousel
      marketingLabel="NEW_ARRIVAL"
      source={{ page, section: 'new_arrivals' }}
      subtitle={headless ? undefined : t('core:new_arrivals_subtitle')}
      title={headless ? undefined : t('core:new_arrivals_title')}
      emptyStateText={t('core:new_arrivals_no_cars')}
      resultsState={resultsState}
      widgetsCollector={widgetsCollector}
      hideTrack={!showTrack}
      hideBrowseAll={hideBrowseAll}
      multiIndex={multiIndex}
      showMarketingLabels={headless}
    />
  )
}

export const fetchNewArrivals = () =>
  dehydrateAlgoliaState(NewArrivals, {
    searchClient,
    indexName: process.env.NEXT_PUBLIC_ALGOLIA_STOCK_CARS_INDEX as string
  })
