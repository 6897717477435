import {
  documentToReactComponents,
  Options,
  RenderNode
} from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { css } from '@emotion/react'
import {
  ArticleDetailFragment,
  ArticleLinksFragment,
  FaqQuestionFragment,
  HiringFragment,
  HiringLinksFragment,
  UpdateDetailFragment,
  UpdateLinksFragment
} from 'api/contentful/generated/contentful'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { addSearchParams } from 'driverama-core/utils/searchParams'
import { Maybe } from 'driverama-core/utils/types'
import { cloneElement, createContext, useContext } from 'react'
import { ImageGalleryPreview } from '../imageGalleryPreview/ImageGalleryPreview'
import {
  SContentHeading,
  SContentImage,
  SContentLink,
  SContentOrderedList,
  SContentQuote,
  SContentText,
  SContentUnorderedList
} from './ContentfulContent.styled'

interface IAssetContext {
  links: Maybe<ArticleLinksFragment | UpdateLinksFragment | HiringLinksFragment>
  imageQuery?: { [key: string]: string | number } | null
}

const LinkContext = createContext<IAssetContext | null>(null)

function AssetNode({ linkId }: { linkId?: string }) {
  const context = useContext(LinkContext)
  const image =
    linkId != null &&
    context?.links?.assets.block.find(item => item?.sys.id === linkId)

  if (!image) return null
  return (
    <div
      css={css`
        position: relative;
        height: ${size(118)};
        margin-bottom: ${size(8)};
        @media ${media.lte('MD')} {
          height: ${size(75)};
        }
        @media ${media.lte('mobile')} {
          height: ${size(40)};
        }
      `}
    >
      <SContentImage
        src={addSearchParams(image?.url ?? '', context?.imageQuery ?? {})}
      />
    </div>
  )
}

const defaultComponents = {
  [BLOCKS.PARAGRAPH]: <SContentText as="p" />,
  [BLOCKS.HEADING_1]: <SContentHeading as="h1" variant="h1" />,
  [BLOCKS.HEADING_2]: <SContentHeading as="h2" variant="h2" />,
  [BLOCKS.HEADING_3]: <SContentHeading as="h3" variant="h3" />,
  [BLOCKS.HEADING_4]: <SContentHeading as="h4" variant="h4" />,
  [BLOCKS.HEADING_5]: <SContentHeading as="h5" variant="h5" />,
  [BLOCKS.HEADING_6]: <SContentHeading as="h6" variant="h6" />,
  [BLOCKS.UL_LIST]: <SContentUnorderedList />,
  [BLOCKS.OL_LIST]: <SContentOrderedList />,
  [BLOCKS.QUOTE]: <SContentQuote />,
  [BLOCKS.EMBEDDED_ASSET]: <AssetNode />,
  [BLOCKS.EMBEDDED_ENTRY]: <ImageGalleryPreview />,
  [INLINES.HYPERLINK]: <SContentLink target="_blank" rel="noreferrer" />
}

function resolveNode(
  key: keyof typeof defaultComponents,
  components?: typeof defaultComponents,
  fallback = defaultComponents
) {
  return components?.[key] ?? fallback[key]
}

type Props = {
  data?:
    | ArticleDetailFragment
    | UpdateDetailFragment
    | HiringFragment
    | FaqQuestionFragment
  imageQuery?: IAssetContext['imageQuery']
  components?: typeof defaultComponents
}

export function ContentfulContent(props: Props) {
  const links =
    props.data?.content && 'links' in props.data?.content
      ? props.data.content.links
      : undefined

  const renderOptions: Options = {
    renderNode: {
      ...Object.keys(defaultComponents).reduce<RenderNode>((memo, key) => {
        memo[key] = (_, children) => {
          return cloneElement(
            resolveNode(
              key as keyof typeof defaultComponents,
              props.components
            ),
            {},
            children
          )
        }
        return memo
      }, {}),

      [BLOCKS.EMBEDDED_ASSET]: node => {
        const linkId = node.data?.target?.sys?.id
        if (typeof linkId !== 'string') {
          return null
        }

        return cloneElement(
          resolveNode(BLOCKS.EMBEDDED_ASSET, props.components),
          { linkId }
        )
      },

      [BLOCKS.EMBEDDED_ENTRY]: node => {
        const linkId = node.data?.target?.sys?.id

        if (typeof linkId !== 'string') {
          return null
        }

        return cloneElement(
          resolveNode(BLOCKS.EMBEDDED_ENTRY, props.components),
          { links }
        )
      },

      [INLINES.HYPERLINK]: (node, children) => {
        return cloneElement(
          resolveNode(INLINES.HYPERLINK, props.components),
          { href: node.data.uri },
          children
        )
      }
    }
  }

  return (
    <LinkContext.Provider
      value={{
        links: links ?? undefined,
        imageQuery: props.imageQuery
      }}
    >
      {props.data?.content?.json &&
        documentToReactComponents(props.data?.content?.json, renderOptions)}
    </LinkContext.Provider>
  )
}
